export const isDevelopmentEnv = process.env.NODE_ENV === 'development';

const localhostUrl = 'http://localhost:8080/update-password';

export const config = {
  notificationDelay: 20000,
  apiUrl: `${process.env.API_URL}/api`,
  setPasswordUrl: isDevelopmentEnv
    ? localhostUrl
    : process.env.FRONTEND_SSO_SET_PASSWORD_URL,
  homeFrontendUrl: process.env.FRONTEND_HOME_URL,
  logLevel: process.env.APP_LOG_LEVEL || 'log',

  defaultLang: 'EN',
  localStorageLang: 'lang'
};
